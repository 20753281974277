module.exports = [{
      plugin: require('/codebuild/output/src2249121640/src/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"withWebp":true,"tracedSVG":true},
    },{
      plugin: require('/codebuild/output/src2249121640/src/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src2249121640/src/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140806072-1"},
    },{
      plugin: require('/codebuild/output/src2249121640/src/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Yoshi Walsh's Blog","short_name":"YoshiWalsh","start_url":"/","background_color":"#333333","theme_color":"#ee531b","display":"minimal-ui","icon":"content/assets/logo.svg"},
    },{
      plugin: require('/codebuild/output/src2249121640/src/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/codebuild/output/src2249121640/src/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src2249121640/src/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://blog.yoshiwalsh.me"},
    },{
      plugin: require('/codebuild/output/src2249121640/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
